<template>
    <div style="height:100%">
        <v-row style="max-width: 1400px" class="mx-auto">
            <v-col cols="12">
                <v-card align="center" justify="center" class="guests-card mx-auto elevation-6">
                    <v-toolbar height="50" color="indigo darken-6 elevation-3" dark flat>
                        <v-toolbar-title class="overline">
                            <!-- {{ $t('couriers.list_of_couriers', { p1: couriers.length, p2: couriersOnlineCount }) }} -->
                            Список гостей
                        </v-toolbar-title>

                        <v-spacer></v-spacer>

                        <!-- <v-btn icon @click="onClickUpdate">
                            <v-icon>mdi-refresh</v-icon>
                        </v-btn> -->
                    </v-toolbar>
                    
                    <div class="py-5" style="color: #673AB7"><v-icon class="mr-1" style="color: #673AB7">mdi-file-document-edit-outline</v-icon>Раздел в разработке...</div>

                </v-card>
            </v-col>
        </v-row>
        <span class="pb-16 d-block" />
    </div>
</template>

<script>
    import config from '../../config'

    export default {
        data() {
            return {
            }
        },
        mounted() {
            console.log('start!')
            this.updateData()
        },

        beforeDestroy() {},

        destroyed() {},
        watch: {},
        computed: {},
        methods: {
            updateData() {
                // this.loading = true
                // this.$store
                //     .dispatch('couriers_get')
                //     .then(res => {
                //         if (res.data.success) {
                //         }
                //         this.loading = false
                //     })
                //     .catch(err => {
                //         this.loading = false
                //         console.log(err)
                //         // this.$store.dispatch('logout').then(() => this.$router.push({ name: 'login' }))
                //     })
            },
        },
    }
</script>

<style lang="stylus" scoped>
    .guests-card
        cursor default
        .v-toolbar
            border-bottom-left-radius 0 !important
            border-bottom-right-radius 0 !important

    .tooltip-text
        display flex
        cursor default

</style>
